import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MyComponent = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('http://localhost:7801/api/stock_trigger');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1>Stocks with good Technicals: </h1>
            <ul>
                {data.map((item, index) => (
                    <li>{item.stock_name} {item.current_price} {item.trigger_price}</li> /* Assuming 'name' field exists */
                    ))}
            </ul>
        </div>
    );
};

export default MyComponent;
